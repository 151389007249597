import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Eploy integration - Rotaready"
          description="An intuitive integration designed to streamline your applicant tracking and employee scheduling."
          url="integrations/eploy"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="Eploy and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">An intuitive integration designed to streamline your applicant tracking and employee scheduling.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">With this integration employee records are created automatically within Rotaready when someone is marked as hired within Eploy; saving you time and removing any duplication of work.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://www.eploy.co.uk" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit Eploy" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <GatsbyImage
                  image={data.heroImage.childImageSharp.gatsbyImageData}
                  alt="Screenshots of Rotaready and Eploy"
                  style={{ maxWidth: '450px' }}
                />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Create employee records automatically" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="The integration between Eploy and Rotaready allows all employee data from Eploy to be pulled directly into Rotaready, automatically creating new employee records. A new employee record will include everything you choose to capture about a new hire within Eploy; from HMRC starter declaration, to their bank details. " />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Employee documents such as contracts and passports, can also be copied from Eploy to Rotaready and are automatically saved against an employee's profile in the relevant document category." />
              </TextBlock>
              <TextBlock>
                <H3 uistyle="brand160" text="Already a Rotaready customer?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About Eploy" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Eploy is the complete Talent Acquisition platform for modern in-house recruitment teams. Eploy combines an award-winning Applicant Tracking System, Talent CRM and Recruitment Analytics into a unified platform that integrates seamlessly into your website to help you recruit top talent faster and smarter. " />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="From job requisitions through to new-hire onboarding, Eploy is the hub of your recruitment activity" />
                    <TextListItem text="Dedicated portals for candidates, hiring managers and your agency partners" />
                    <TextListItem text="Award-winning dashboard technology lets you measure what matters most" />
                  </UL>
                </TextBlock>
              </BenefitBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about Eploy"
                  render={props => <a {...props} href="https://www.eploy.co.uk" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/eploy/hero-desktop.webp" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
